import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

import Layout from '../components/layout'
import styles from './index.module.css'

const IndexPage = ({data}) => {

  const allArticles = data.allNodeArticle.edges.map(
    edge => edge.node
  )

  const ArticleLink = ({article}) => {
    let image = (<div className={styles.articleNoImage} />);
    if (article.relationships.field_image) {
      if (article.relationships.field_image.localFile) {
        image = (
          <Img
            className={styles.articleImage}
            fluid={
              article.relationships.field_image.localFile
                .childImageSharp.fluid
            }
          />
        );
      }
    }

    let path = "/article/" + article.nid;
    if (article.path.alias) {
      path = article.path.alias;
    }

    return (
      <React.Fragment>
          { image }
        <div className={styles.articleLink}>
          <div className={styles.articleDescription}>
            <Link to={path}>
              <h2 className={styles.articleTitle}>{article.title}</h2>
              <p className={styles.articleExcerpt}>{article.body.summary}</p>
            </Link>
          </div>
        </div>
      </React.Fragment>
    )
  };

  return (
    <Layout>
      <div className={"article-links"} style={{ maxWidth: '100%', marginBottom: '1.45rem' }}>
        {
          allArticles.map(article => (<ArticleLink article={article}/>))
        }
      </div>
    </Layout>
  );
}



export const query = graphql`
query{
  allNodeArticle(sort: {fields: [created], order: DESC}) {
    edges {
      node {
        path {
          alias
        }
        created
        nid
        title
        body {
          summary
        }
        relationships {
          field_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1024, maxHeight: 120) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
        }
      }
    }
  }
}
`

export default IndexPage
